// State
export const state = () => ({
    user: {},
    loading: false,
    googleAuth: null,
    wlType: null,
})

// Actions
export const actions = {
    async login({ commit, dispatch, rootGetters }, userData) {
        try {
            commit('setLoading', true)
            return await this.$axios
                .post(`auth.login`, userData)
                .then(async (response) => {
                    const responseData = response.data
                    if (
                        !(
                            responseData.id &&
                            responseData.name &&
                            responseData.phone &&
                            responseData.username
                        )
                    ) {
                        commit('setGoogleAuth', response.data)
                        this.$cookie.set('sub_key', response.headers.token)
                    } else {
                        responseData.token = response.headers.token

                        commit('setLogin', responseData)
                        this.$toast.success('Logged-in, Welcome aboard!')
                        const cookieExpiredTime = 60 * 60 * 24 - 60 // 23 hours and 59 minutes

                        // Local storing
                        this.$cookie.set('token', responseData.token, {
                            path: '/',
                            maxAge: cookieExpiredTime,
                        })
                        this.$cookie.set('user', responseData, {
                            path: '/',
                            maxAge: cookieExpiredTime,
                        })

                        this.$cookie.remove('sub_key')
                        // set white label type, 1 is international, 2 is lao
                        this.$cookie.set('wlType', responseData.wl_type, {
                            path: '/',
                            maxAge: cookieExpiredTime,
                        })
                        // set currency
                        if (responseData.currency)
                            this.$cookie.set('currency', responseData.currency)
                        else {
                            // Setting default currency before logging in
                            await dispatch(
                                'settings/currency',
                                {},
                                { root: true }
                            )
                        }

                        // set markup for reports
                        if (
                            responseData.markup_1 ||
                            responseData.markup_1 === 0
                        )
                            this.$cookie.set(
                                'markup_1',
                                responseData.markup_1,
                                {
                                    path: '/',
                                    maxAge: cookieExpiredTime,
                                }
                            )
                        if (
                            responseData.markup_2 ||
                            responseData.markup_2 === 0
                        )
                            this.$cookie.set(
                                'markup_2',
                                responseData.markup_2,
                                {
                                    path: '/',
                                    maxAge: cookieExpiredTime,
                                }
                            )
                        // set locale by user default
                        if (responseData.language) {
                            this.$cookie.set('locale', responseData.language)
                        } else {
                            this.$cookie.set('locale', 'th-TH')
                        }

                        // checking the permissions
                        if (responseData.user_type === 1) {
                            if (this.$cookie.get('routePath')) {
                                if (this.$cookie.get('routePath') === '/login')
                                    this.$router.push('/')
                                else
                                    this.$router.push(
                                        this.$cookie.get('routePath')
                                    )
                            } else {
                                this.$router.push('/')
                            }
                        } else {
                            await dispatch(
                                'settings/redirectToHasPermissionRoute',
                                true,
                                { root: true }
                            )
                        }
                    }
                    commit('setLoading', false)
                })
        } catch (error) {
            commit('setLoading', false)
            commit('setGoogleAuth', null)
        }
    },
    async verifyGoogle({ commit, rootGetters, dispatch }, form) {
        try {
            if (!form || Object.keys(form).length === 0) {
                commit('setGoogleAuth', null)
                return
            }
            commit('setLoading', true)
            const response = await this.$axios
                .post(`auth.verify`, form, {
                    headers: {
                        Authorization: 'Bearer ' + this.$cookie.get('sub_key'),
                    },
                })
                .then(async (response) => {
                    const responseData = response.data
                    responseData.token = response.headers.token

                    commit('setLogin', responseData)
                    this.$toast.success('Logged-in, Welcome aboard!')
                    const cookieExpiredTime = 60 * 60 * 24 - 60 // 23 hours and 59 minutes

                    // Local storing
                    this.$cookie.set('token', responseData.token, {
                        path: '/',
                        maxAge: cookieExpiredTime,
                    })
                    this.$cookie.set('user', responseData, {
                        path: '/',
                        maxAge: cookieExpiredTime,
                    })

                    // set white label type, 1 is international, 2 is lao
                    this.$cookie.set('wlType', responseData.wl_type, {
                        path: '/',
                        maxAge: cookieExpiredTime,
                    })
                    // set currency
                    if (responseData.currency)
                        this.$cookie.set('currency', responseData.currency)
                    else {
                        // Setting default currency before logging in
                        await dispatch('settings/currency', {}, { root: true })
                    }
                    // set Mark Ups for reports
                    if (responseData.markup_1 || responseData.markup_1 === 0)
                        this.$cookie.set('markup_1', responseData.markup_1, {
                            path: '/',
                            maxAge: cookieExpiredTime,
                        })
                    if (responseData.markup_2 || responseData.markup_2 === 0)
                        this.$cookie.set('markup_2', responseData.markup_1, {
                            path: '/',
                            maxAge: cookieExpiredTime,
                        })

                    this.$cookie.remove('sub_key')
                    // set locale by user default
                    if (responseData.language) {
                        this.$cookie.set('locale', responseData.language)
                    } else {
                        this.$cookie.set('locale', 'th-TH')
                    }

                    // checking the permissions
                    if (responseData.user_type === 1) {
                        if (this.$cookie.get('routePath')) {
                            this.$router.push(this.$cookie.get('routePath'))
                        } else {
                            this.$router.push('/')
                        }
                    } else {
                        await dispatch(
                            'settings/redirectToHasPermissionRoute',
                            true,
                            { root: true }
                        )
                    }

                    commit('setLoading', false)
                    return true
                })
                .catch(() => {
                    return false
                })
            return response
        } catch (error) {
            commit('setLoading', false)
            return false
        }
    },

    async logout({ rootGetters }) {
        try {
            await this.$axios.get('auth.logout')
            this.$cookie.remove('token')
            this.$cookie.remove('permissionCode')
            this.$cookie.remove('routePath')
            this.$cookie.remove('ia') // Check if it's agent
            this.$toast.info(rootGetters['master/storeTl']('logoutSeeYou'))
            this.$router.push('/login')
        } catch (error) {
            // this.$toast.error(error)
            return false
        }
    },
    checkWhiteLabel({ commit }) {
        let whiteLabelType = 1

        if (
            this.$config.IS_LAO === true ||
            this.$config.IS_LAO?.toString()?.toLowerCase() === 'true'
        ) {
            whiteLabelType = 2
        }

        commit('setWlType', whiteLabelType)
        this.$cookie.set('wlt', whiteLabelType)
    },
}

// Mutations
export const mutations = {
    setLogin(state, response) {
        state.user = response
    },
    setLoading(state, type) {
        state.loading = type
    },
    setGoogleAuth(state, data) {
        state.googleAuth = data
    },
    setWlType(state, data) {
        state.wlType = data
    },
}
